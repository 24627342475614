textarea {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #686868;
  font-size: 18px;
  height: 200px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  padding: 10px;
  resize: none;
}
