.fraction-calc-wrap {
  display: flex;
  align-items: center;
  gap: 8px;

  & > * {
    width: 25%;
  }

  .square-root-container {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 45px;
    margin: 20px;
    width: 50%;

    .square-root-symbol {
      margin-right: 8px;
    }

    .general-root-input {
      margin-bottom: 60px !important;
    }
  }

  .result-preview {
    display: flex;
    align-items: center;
    gap: 4px;

    p {
      font-size: 24px;
    }
  }
}
