.input-field {
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #61b0c1;
  max-height: 48px;
  color: white;
  -moz-appearance: "textfield" !important;
  appearance: "textfield" !important;
  padding: 12px;
  text-align: start;
  font-size: 20px;
  width: calc(100% - 24px);

  @media (max-width: 450px) {
    max-width: 180px;
  }
}

input.input-field[type="number"]::-webkit-inner-spin-button,
input.input-field[type="number"]::-webkit-outer-spin-button {
  appearance: none;
}
