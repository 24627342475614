.chart-container {
  width: 90%;
  height: 40px;
  position: relative;
  display: flex;
  margin: 50px auto;

  .segment {
    height: 100%;
  }

  #safe {
    background-color: green;
    width: 40%;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
  }
  #acceptable {
    background-color: yellow;
    width: 20%;
    span {
      color: black !important;
    }
  }

  #aggressive {
    background-color: red;
    width: 40%;
  }

  .marker {
    position: absolute;
    width: 2px;
    height: 48px;
    background-color: black;
  }

  .label {
    position: absolute;
    top: 50px;
    font-weight: bold;
  }

  #label1 {
    color: green;
  }

  #label2 {
    color: #404040;
  }
}
