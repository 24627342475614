/* ScientificCalculator.css */
.scientific-calculator {
  width: 42vw;
  margin: 20px auto;
  max-width: 1290px;
  padding: 12px;
  text-align: center;
  background-color: #f9feff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 500px) {
    width: 95vw;
    margin: auto;
    margin-top: 8px;
  }

  @media (max-width: 800px) and (min-width: 500px) {
    width: 92vw !important;
  }
  @media (min-width: 800px) {
    width: 100%;
    max-width: 624px !important;
  }

  .display {
    background-color: #61b0c1;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 10px auto;
    font-size: 24px;
    width: 96%;
    overflow: hidden;

    text-align: right;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  .button-number {
    background-color: #61b0c1 !important;
  }

  button {
    margin: 2px;
    padding: 3px;
    font-size: 14px;
    border-radius: 5px;
    background-color: #295fa6;
    color: #fff;
    cursor: pointer;
    text-transform: none !important;
    @media (max-width: 500px) {
      margin: 1px;
      padding: 2px;
      font-size: 10px;
    }
  }

  button:hover {
    background-color: #295fa6 !important;
  }

  button:active {
    transform: translateY(2px);
  }

  sup {
    vertical-align: super;
    font-size: smaller;
    margin-top: -5px !important;
    margin-left: 1px;
  }

  .btn-backspace {
    background-color: #c72727 !important;
    svg {
      height: 22px;
      padding: 2px 0px;
      @media (max-width: 500px) {
        height: 16px;
      }
    }
  }
}
