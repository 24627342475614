.unit-heading {
  display: flex;
  justify-content: space-between;
  margin: 6px;
  align-items: center;
}
.unit-close-icon {
  color: #295fa6;
  font-size: 32px;
  cursor: pointer;
}
