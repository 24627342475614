.gaugechart-div {
  display: flex;
  padding-left: 50px;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 60px;
  transition: display 1s ease-in-out;
  @media (max-width: 600px) {
    padding-left: 0px !important;
  }
}
