.concrete-calc-input {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  .input-label {
    text-align: left;
    color: #272727;
    font-weight: 500;
    font-size: 18px;
  }

  & > * {
    width: 100%;
  }

  .select-box-wrap {
    width: 100%;
    & > div {
      & > * {
        margin: 0;

        & > * {
          background: #61b0c1;
          color: white;
          font-size: 20px;

          .MuiSelect-select {
            padding: 12px 32px 12px 12px;
          }

          fieldset {
            border: 0;
          }
        }
      }
    }
    .select-box-hover-rm {
      margin-top: 0 !important;
      border-radius: 5px !important;
    }
  }
}

.calculation-part .concrete-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pace {
  @media (max-width: 624px) {
    flex-direction: column !important;
  }
}
