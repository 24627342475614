.card-container {
  max-width: 100%;
  max-height: 300px;
  min-width: 100% !important;
  position: relative;
  background-color: #f9fbff !important;
  border: 1px solid #295fa6 !important;
  border-radius: 8px !important;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3));
  animation: zoom-in 0.7s, fadeIn 0.8s;

  &:hover {
    background-color: #f6f6f6;
    .card-detail-text {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }
  @keyframes zoom-in {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: none;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  .card-media-img {
    width: auto !important;
    position: absolute;
    bottom: 12px;
    right: 12px;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }
  .card-media-img:hover {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    .card-detail-text {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }
  .card-title {
    color: #295fa6;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  .card-content-div {
    padding: 12px 21px 9px 21px;
  }
  .card-btn:hover {
    & + .card-media-img {
      opacity: 1 !important;
      transition: opacity 0.3s ease;
    }
  }
  .card-btn:focus {
    & + .card-media-img {
      opacity: 1 !important;
      transition: opacity 0.3s ease;
    }
  }
  .card-detail-text {
    color: #272727;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
  }
}
