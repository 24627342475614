

.error-massage {
  color: red;
  padding: 2px 6px;
}

.fraction-calc-wrap {
  display: flex;
  align-items: center;
  gap: 8px;

  &>* {
    width: 25%;
  }

  .select-box-wrap {
    &>div {
      &>* {
        margin: 0;

        &>* {
          background: #61b0c1;
          color: white;
          font-size: 20px;

          .MuiSelect-select {
            padding: 10px 32px 10px 12px;
          }

          fieldset {
            border: 0;
          }
        }
      }
    }
  }

  .result-preview {
    display: flex;
    align-items: center;
    gap: 4px;

    .line {
      height: 2px;
      width: 12px;
      background-color: #000;
    }

    p {
      font-size: 24px;
    }
  }
}

.big-fraction-calc {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;

}