// .css-1wvnaxz-MuiTableCell-root.MuiTableCell-head {
//     background-color: #295fa6 !important;
//     font-weight: bold !important;
// }

.tableBorder td {
  border: 2px solid #295fa6;
}


.gpa-final-result {
  color: #686868;
  font-size: 18px !important;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-align: center;
  margin: 14px auto !important;
}
