.common-datepicker-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  .input-label {
    color: #272727;
    font-weight: 500 !important;
    font-size: 18px !important;
    letter-spacing: 1px !important;
  }
}
