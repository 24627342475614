#label {
    color: #272727;
    font-weight: 600;
    font-size: 18px;
}

.input {
    border-radius: 5px;
    background-color: #61b0c1;
    -moz-appearance: "textfield" !important;
    appearance: "textfield" !important;

    @media (max-width: 450px) {
        width: 180px;
    }
}