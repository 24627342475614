.back-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0 10px 0px !important;
  gap: 10px;
  letter-spacing: 1px;
  font-size: 16px;
  color: #295fa6;
  font-weight: 600 !important;
}
.header-class {
  background-color: white !important;
  border-bottom: 2px solid #295fa6 !important;
  box-shadow: none !important;
}
.container-class {
  max-width: 1290px !important;
  padding-inline: 0px !important;
}
.header-search {
  border: 1px solid #61b0c1 !important;
  border-radius: 8px !important;
  color: #747575 !important;

  input {
    @media (min-width: 1200px) {
      width: 60ch !important;

      &:focus {
        width: 60ch !important;
      }
    }
    @media only screen and (min-width: 945px) and (max-width: 1260px) {
      width: 40ch !important;

      &:focus {
        width: 40ch !important;
      }
    }
    @media only screen and (min-width: 600px) and (max-width: 945px) {
      width: 20ch !important;

      &:focus {
        width: 20ch !important;
      }
    }
  }
}
.search-result {
  color: #747575;
  background: white;
  border: 1px solid #61b0c1;
  border-radius: 5px !important;
  z-index: 1;
  position: absolute;
  left: 10px;
  max-height: 360px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #295fa6;
    outline: 1px solid #295fa6;
  }
  p {
    padding: 8px 16px;
    font-size: 18px;
    letter-spacing: 0.5px;
    cursor: pointer;
    &:hover {
      background-color: #f2f2f2;
    }
    span {
      color: #61b0c1;
    }
  }
  .slide-up {
    animation: 0.3s slide-up;
    margin-top: 0%;
  }

  @keyframes slide-up {
    from {
      margin-top: 100%;
      height: 300%;
    }

    to {
      margin-top: 0%;
      height: 100%;
    }
  }

  @media (min-width: 1200px) {
    width: 65ch !important;
  }
  @media only screen and (min-width: 945px) and (max-width: 1260px) {
    width: 45.5ch !important;
  }
  @media only screen and (min-width: 600px) and (max-width: 945px) {
    width: 26ch !important;
  }
}

.header-mainbar {
  padding-left: 16px !important;
  padding-right: 16px !important;
  gap: 50px;
  @media (max-width: 440px) {
    gap: 10px !important;
  }
}

.header-logo {
  cursor: pointer;
  height: 30px;
  width: auto;
  @media (max-width: 599px) {
    height: 20px;
  }
}

.goog-te-combo {
  border: 1px solid #61b0c1 !important;
  border-radius: 5px !important;
  color: #747575 !important;
  padding: 5px;
  outline: none;
}

.switch-btn {
  background: white;
  padding: 7px;
  border-radius: 6px;

  @media (max-width: 768px) {
    border: 1px solid #aaa;
    z-index: 23;
    position: fixed;
    bottom: 3%;
    right: 2%;
  }
}

.component-heading{
  h1{
    font-size: 24px !important;
    
  }
}