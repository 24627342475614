.home-loan-container {
  border: 2px solid #eee;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 10px;
}
.data-table-container {
  text-align: center;
  color: #7c7e8c;
  margin-top: 10px;
  .add-icon-table {
    transition: transform 0.3s linear;
    cursor: pointer;
    font-size: 38px;
    height: 45px;
    width: auto;
  }
}

.piechart-div {
  display: flex;
  padding-left: 50px;
  flex-direction: column !important;
  align-items: center !important;
  gap: 60px;
  transition: display 1s ease-in-out;
  @media (max-width: 600px) {
    padding-left: 16px;
  }
}

//This is used into DateOfBirthCalc
.dob-date-picker {
  .common-datepicker-parent {
    @media (max-width: 600px) {
      display: block !important;
    }
  }

  
}
.center-align{
  text-align: center !important;
  cursor: pointer !important;
}