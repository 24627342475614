.blog-container {
  padding-inline: 30px;
  margin: 10px auto;
  @media (max-width: 900px) {
    padding: 0 16px 0 0;
  }
  .blog-content {
    h2 {
      font-size: clamp(18px, 6vw, 48px) !important;
      word-break: break-word;
      font-weight: 600 !important;
      margin-left: -5px !important;
      letter-spacing: 1px;
      color: #295fa6;
    }
  }
  h2 {
    color: #61b0c1;
  }
  h3 {
    color: #272727;
  }
  .blog-content {
    p,
    li {
      color: #44475b;
    }
    figure {
      display: flex;
      justify-content: center;
    }
    img {
      width: 100% !important;
      aspect-ratio: auto !important;
    }
  }
}
