.date-calc-container {
  .date-calc-sub-title {
    margin-bottom: 4px;
    opacity: 0.6;

    padding: 2px 6px;
  }
  .date-calc-open-date-picker {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 700px) {
      display: none;
    }
  }
}
.radio-btn-grp {
  padding: 8px;
}
.holiday-list-div {
  margin-left: 30px;
}
