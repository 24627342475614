.age-calc-assets {
  max-width: 100%;
  border-radius: 8px;
  height: auto;
  width: auto;
}

.age-calc-err {
  // color: #404040;
  font-size: 18px !important;
  letter-spacing: 1px !important;
  color: #d32f2f;
  text-align: center;
  margin: auto;
  padding-top: 24px;
}
.age-calc-input-div {
  .input-div {
    display: flex !important;
  }
}
.date-picker-age-calc {
  .common-datepicker-parent {
    @media (max-width: 482px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
