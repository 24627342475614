#settings .data-table-container {
  text-align: center;
  color: black !important;
  margin-top: 10px;
  .add-icon-table {
    transition: transform 0.3s linear;
    cursor: pointer;
    font-size: 38px;
    height: 45px;
    width: auto;
  }
}
