.grade-show-text {
  color: #686868;
  font-size: 18px !important;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8px;
  margin-right: 5px;

  span {
    font-size: 20px;
  }
}
