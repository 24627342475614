.home-card {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin: 8px auto;
  width: 90%;
  margin-bottom: 16px;
}

.heading-calcs {
  margin-left: 16px;
  margin-top: 5px;
  height: 28px;
  width: auto;
}
.homepage-tabbing-container {
  .Mui-selected {
    color: #295fa6 !important;
  }
  .MuiTabs-indicator {
    background: #295fa6 !important;
  }
  .MuiTabs-flexContainer {
    justify-content: space-between !important;
  }
}
.search-not-found {
  color: #61b0c1 !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  span {
    color: #295fa6;
  }
}
.loader-div-sci-calc {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
