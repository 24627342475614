.vertical-tabs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 275px;
  width: 100%;
  flex-grow: 1;
  height: 700px;
  position: sticky;
  top: 50px;
  .vertical-tabs-div {
    button {
      display: flex;
      align-items: flex-start !important;
      text-align: left !important;
    }
    .MuiTabs-indicator {
      background-color: transparent !important;
    }
  }
  .vertical-tab-label {
    font-size: 20px;
    font-weight: bold;
    color: #295fa6;
    text-transform: capitalize;
  }
}
