.related-calc-div {
  position: relative !important;
  .related-heading {
    font-size: 20px !important;
    position: absolute !important;
    top: -18px;
    left: 20px;
    background: #337ab7;
    color: white;
    border-radius: 5px;
    padding: 2px 10px;
  }
}

.similar {
  padding: 6px;
  background-color: #337ab7;
  color: #fff;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  font-size: 24px;
  margin-bottom: 16px !important;
  font-size: 18px !important;
  cursor: pointer;
}

.similar-calc-div {
  max-width: calc(50% - 10px) !important;
}
