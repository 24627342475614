.input-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 20px;

  @media (max-width: 624px) {
    display: block;
  }

  .input-label {
    color: #272727;
    font-weight: 500;
    font-size: 18px;
  }

  .amount-value {
    color: #404040;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 700;
    word-break: break-all;
  }

  .common-input-parent {
    max-width: 50%;
    min-width: 50%;
    width: 100%;
    input {
      outline: none;
      width: 100%;
      border: none;
      border-radius: 5px;
      background-color: #61b0c1;
      max-height: 48px;
      color: white;
      -moz-appearance: "textfield" !important;
      appearance: "textfield" !important;
      padding: 12px 8px 12px 24px;
      text-align: start;
      font-size: 20px;

      @media (max-width: 486px) {
        width: 100%;
      }
    }
    textarea {
      outline: none;
      border: none;
      border-radius: 5px;
      background-color: #61b0c1;
      color: white;
      -moz-appearance: "textfield" !important;
      appearance: "textfield" !important;
      padding: 12px 8px 12px 34px;
      text-align: start;
      font-size: 20px;

      @media (max-width: 486px) {
        width: 100%;
      }
    }

    @media (max-width: 624px) {
      max-width: 100%;
    }

    @media (max-width: 486px) {
      width: 100%;
    }
  }

  .span-percentage {
    position: absolute;
    right: 8px;
    color: white;
    @media (max-width: 620px) and (min-width: 450px) {
      right: 30px;
    }
  }
  .label-value {
    color: #686868;
    font-size: 18px;
    min-width: fit-content;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
  }

  .class-rupee-dollar {
    padding: 12px 8px 12px 24px !important;
    text-align: auto !important;
  }
  .class-except-rupee-dollar {
    padding: 12px 35px 12px 8px !important;
    text-align: end !important;
  }
  .class-except-month-day {
    padding: 12px 40px 12px 8px !important;
    text-align: end !important;
  }
}
