.err-page-container {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 40px;
  img {
    height: 42vh;
    width: auto;
  }
  button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 20px;
    border-radius: 8px;
    border: none;
    background: #1976d2;
    color: white;
    font-size: 18px;
    cursor: pointer;
    margin: 10px auto;
    transition: padding 0.3s ease-in-out;
  }
  button:hover {
    padding: 8px 100px;
    transition: padding 0.3s ease-in-out;
  }
  button:active {
    transform: translateY(3px);
  }
}
