.contact-us-component {
  text-align: center;
  .input-detail-div {
    text-align: left;
    border: 1px solid #295fa6;
    border-radius: 5px;
    padding: 20px;

    .input-div {
      display: block;
      .common-input-parent {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
  button {
    margin-top: 16px;
    background-color: #295fa6;
    width: 50%;
    &:hover,
    &:focus {
      background-color: #295fa6;
    }
  }
}
