.select-box {
  padding: 8px;
  align-items: center;
  @media (max-width: 624px) {
    display: block !important;
  }
  .select-frequency {
    color: #272727;
    font-weight: 500;
    font-size: 18px;
  }
}
.select-box-wrap {
  @media (max-width: 624px) {
    max-width: 273px;
  }
  @media (max-width: 450px) {
    max-width: 213px;
  }
  & > div {
    & > * {
      margin: 0;
      width: 100%;
      & > * {
        background: #61b0c1;
        color: white;
        font-size: 20px;

        .MuiSelect-select {
          padding: 10px 32px 10px 12px;
        }

        fieldset {
          border: 0;
        }
      }
    }
  }
}
