.table-container {
  border: 2px solid #295fa6 !important;
  max-height: 586px !important;
  height: max-content !important;
  overflow-y: auto !important;

  .common-table {
    border-collapse: unset !important;
    .MuiTableCell-head {
      background-color: #295fa6 !important;
      font-weight: bold !important;
    }
    thead {
      position: sticky;
      top: -1px;
    }
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #61b0c1;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
