.select-option {
  outline: none;
  border: none;
  border-radius: 5px !important;
  background-color: #61b0c1;
  max-height: 48px;
  color: white !important;
  appearance: "textfield" !important;
  text-align: start;
  font-size: 20px !important;

  &:hover {
    border: none !important;
    outline: none !important;
  }

  svg {
    fill: white;
  }
}
.select-box-label {
  color: #272727;
  font-weight: 500 !important;
  font-size: 18px !important;
  margin-bottom: 2px !important;
}
