.matrix-cells {
  width: 40px;
  margin: 2px;
  text-align: center;
}

.random-clear-btn {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.matrix-inputs-parent {
  margin-top: 10px;
  width: 100%;
  overflow-x: auto;
}

.matrix-result {
  display: flex;
  align-items: center;

  @media (max-width: 570px) {
    display: block;
  }

  .matrix-label {
    min-width: fit-content;
  }
}
