.love-detail {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  .love-name {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .love-percentage {
    font-size: 24px;
    font-weight: 500;
  }
  .love-message {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
}
