html {
  touch-action: manipulation;
}
body {
  position: initial !important;
  top: 0 !important;
}
.App {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: white;
}
a:hover {
  text-decoration: underline;
}
.table-data-error {
  text-align: center !important;
  font-size: 20px !important;
  color: red !important;
}
.css-1mhcdve-MuiPieArc-root {
  cursor: pointer;
}
.MuiFormControlLabel-label{
  font-weight: 600 !important;
  font-size: 18px !important;
}
.calculation-part {
  background-color: #f9feff;
  padding: 8px;
  border-radius: 8px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
  animation: zoom-in 0.5s, fadeIn 0.6s;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.calender-width-set {
  width: 48% !important;
  margin: 10px 0px;
  .MuiDateCalendar-root {
    background: #61b0c1 !important;
    border-radius: 8px !important;
  }
}

.editable-math-field {
  display: flex !important;
  outline: none !important;
  border: none !important;
  border-radius: 5px;
  background-color: #61b0c1;
  max-height: 48px !important;
  color: white;
  appearance: unset;
  padding: 8px !important;
  text-align: start;
  width: 94%;
  margin: auto;
  font-size: 20px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
}

.common-sub-heading-calc {
  font-size: 22px !important;
  color: #295fa6;
  font-weight: 600 !important;
  padding: 2px 6px;
}

.MuiButton-contained {
  background-color: #295fa6 !important;
}
.common-sub-heading-desc {
  font-size: 18px !important;
  color: #295fa6;
  font-weight: 550 !important;
  padding: 8px 6px;
}

.common-animation {
  animation: zoom-in 0.5s, fadeIn 0.6s;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.result-common-div {
  .input-div {
    display: flex !important;
  }
}
.table-toggle-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin: 24px 0;
  h5 {
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 1.5rem;
    color: #295fa6 !important;
    font-weight: 600 !important;
    text-align: left;
    span {
      color: #747575;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}
.goog-te-combo {
  background-color: white !important;
}

.result-label {
  padding: 8px 0;
  text-align: center;
  color: white;
  background: #699c00;
  margin: 10px -8px 4px -8px;
  p {
    font-size: 18px;
    font-weight: 900;
  }
}

.select-box-hover-rm {
  fieldset {
    border: none !important;
  }
}

.piechart-div .css-1cj1zx6-MuiResponsiveChart-container {
  width: -webkit-fill-available !important;
}

.extra-input-for-old-job {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 3px 12px;
}

#goog-gt-tt {
  display: none !important;
}

.extra-percentage-setter{
  display: flex;
  justify-content: right;
  align-items: center;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  gap: 12px;
  margin: 10px;
  p{
    font-size: 18px;
  }
}