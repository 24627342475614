.footer-box {
  background: #295fa6;
  color: white;
  padding: 20px 16px 6px 16px;

  .footer-main-title {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    align-items: flex-start;
    margin-top: 12px;
    justify-content: space-evenly;
    .item-title {
      margin-top: 4px;

      font-weight: bold;
      span {
        a {
          cursor: pointer;
          color: white;
          text-decoration: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .items {
      font-size: 16px;
    }
  }
  .address-details {
    display: flex;
    flex-direction: column !important;
    align-items: baseline;
    justify-content: space-around;
    .address {
      word-break: break-word;
      max-width: 300px;
      margin-bottom: 4px;
    }

    .icon-div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      cursor: pointer;
      img {
        height: 52px;
        width: auto;
      }
    }
    h3 {
      margin: 0;
    }
  }
  .divider {
    // margin: 20px auto 0px !important;
    background: white;
  }
}
.footer-logo {
  margin-bottom: 15px;
  cursor: pointer;
  height: 30px;
  width: auto;
  max-width: 100%;
  @media (max-width: 599px) {
    height: 20px;
  }
}
.d-flex-align {
  display: flex;
  align-items: center;
}
.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  @media (max-width: 650px) {
    flex-direction: column !important;
  }
  .footer-bottom-line {
    letter-spacing: normal !important;
    margin: 16px 0 !important;

    line-height: normal !important;
    color: white !important;
    text-decoration: none !important;
    span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
